export enum IntegrationContractField {
  DEPARTMENT = 'sync_contract_department',
  END_DATE = 'sync_contract_end_date',
  HOURS = 'sync_contract_hours',
  TYPE = 'sync_contract_type',
  FUNCTION = 'sync_contract_function',
  WAGE = 'sync_contract_wage',
}

export const IntegrationContractSync = {
  [IntegrationContractField.DEPARTMENT]: 'Departments',
  [IntegrationContractField.END_DATE]: 'End dates',
  [IntegrationContractField.HOURS]: 'Contract hours',
  [IntegrationContractField.TYPE]: 'Contract types',
  [IntegrationContractField.FUNCTION]: 'Function',
  [IntegrationContractField.WAGE]: 'Wages',
};

export const IntegrationContractSyncDefaultValues = {
  [IntegrationContractField.DEPARTMENT]: true,
  [IntegrationContractField.END_DATE]: true,
  [IntegrationContractField.HOURS]: true,
  [IntegrationContractField.TYPE]: true,
  [IntegrationContractField.FUNCTION]: true,
  [IntegrationContractField.WAGE]: true,
};

export enum IntegrationEmployeeField {
  ADDRESS = 'sync_employee_address',
  BANK_ACCOUNT = 'sync_employee_bank_account',
  BIRTH_DATE = 'sync_employee_birth_date',
  BIRTH_PLACE = 'sync_employee_birth_place',
  EMPLOYEE_NR = 'sync_employee_employee_nr',
  NATIONALITY = 'sync_employee_nationality',
  PASSPORT_NR = 'sync_employee_passport_nr',
  PHONE_NUMBERS = 'sync_employee_phone_nrs',
  SSN = 'sync_employee_ssn',
}

export const IntegrationEmployeeSync = {
  [IntegrationEmployeeField.ADDRESS]: 'Address',
  [IntegrationEmployeeField.BANK_ACCOUNT]: 'Bank account',
  [IntegrationEmployeeField.BIRTH_DATE]: 'Birth date',
  [IntegrationEmployeeField.BIRTH_PLACE]: 'Birth place',
  [IntegrationEmployeeField.EMPLOYEE_NR]: 'Employee nr',
  [IntegrationEmployeeField.NATIONALITY]: 'Nationality',
  [IntegrationEmployeeField.PASSPORT_NR]: 'Passport nr',
  [IntegrationEmployeeField.PHONE_NUMBERS]: 'Phone numbers',
  [IntegrationEmployeeField.SSN]: 'SSN',
};

export const IntegrationEmployeeSyncDefaultValues = {
  [IntegrationEmployeeField.ADDRESS]: true,
  [IntegrationEmployeeField.BANK_ACCOUNT]: true,

  [IntegrationEmployeeField.BIRTH_DATE]: true,
  [IntegrationEmployeeField.BIRTH_PLACE]: true,

  [IntegrationEmployeeField.EMPLOYEE_NR]: true,
  [IntegrationEmployeeField.NATIONALITY]: true,

  [IntegrationEmployeeField.PASSPORT_NR]: true,
  [IntegrationEmployeeField.PHONE_NUMBERS]: true,

  [IntegrationEmployeeField.SSN]: true,
};
